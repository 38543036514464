import Default from 'gatsby-theme-carbon/src/templates/Default';
import React, {useState, useEffect} from 'react'

import SubmitListingButton from '../../components/SubmitListingButton'
import SubmitListingForm from "../../components/submit-listing-form"
import JobBody from "../../components/job-body"
import {Entity} from '@parallelpublicworks/entitysync'
import {ToastNotification, Modal} from 'carbon-components-react'
import {useJobListingValidationState, formatJobData} from '../../util'
import {graphql} from 'gatsby'


export default function SubmitNewListing({data, pageContext, location}) {
    const [hasErrors, setHasErrors] = useState(null);
    const validationState = useJobListingValidationState();
    const [open, setOpen] = useState(false);
    const [unsavedObject, setUnsavedObject] = useState(null);
    const [fakePageContext, setFakePageContext] = useState(null);
    const [jobData, setJobData] = useState(null);
    const geolocationState = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if(unsavedObject){
        setFakePageContext({
          relativePagePath: "/provider-landing/submit-new-listing.js", 
          titleType: "page",
          frontmatter:
          {
            title: unsavedObject.attributes.title
          }
        })
        let job_data = formatJobData(unsavedObject, data, 'localstorage')
        setJobData(job_data)
      }
    }, [unsavedObject])
    if(!location){
      return (
        <></>
      );
    }

    return (
        <div>
          <Modal passiveModal className="preview-modal" key="modal" size="lg" open={open} onRequestClose={() => {
            setOpen(false);
            setLoading(false);
          }} >
            {fakePageContext && jobData && <Default pageContext={fakePageContext} location={location}>
              <JobBody job_data={jobData} objectType="localstorage" />
            </Default>}
          </Modal>                      
          <Entity type="node--job_listing" componentId="submit-new-listing">
              <h2>Please enter your new job listing below</h2>
              {hasErrors && <div className="toast-container">
                <ToastNotification
                  caption=""
                  iconDescription="Close"
                  subtitle={<span>{hasErrors}</span>}
                  timeout={0}
                  title="Error"
                  kind="error"
                  lowContrast="true"
                  onCloseButtonClick={(e) => setHasErrors(null)}
                />
              </div>}
              <SubmitListingForm isNew={true} validationState={validationState} geolocationState={geolocationState}/>
              <SubmitListingButton setOpen={setOpen} validationState={validationState} data={data} setHasErrors={setHasErrors} setUnsavedObject={setUnsavedObject} geolocation={geolocationState[0]} loading={loading} setLoading={setLoading} />
          </Entity>
        </div>
    )
}


export const query = graphql`
query SubmitNewListingQuery {
  allNodeJobListing(sort: {fields: field_publish_date, order: DESC}, filter: {changed: {gt: "2022-01-01"}, field_publish_date: {gt: "2022-01-01"}}) {
    nodes {
      drupal_id
      changed
      title
      field_expired
      field_publish_date
      field_archived
      field_company_name
      field_preferred_qualifications {
        value
      }
      field_required_qualifications {
        value
      }
      field_description {
        value
      }
      field_benefits {
        value
      }
      field_address {
        address_line1
        administrative_area
        locality
        postal_code
      }
      field_salary_number
      field_salary_number_max
      relationships {
        uid {
          drupal_id
        }
        field_category {
          drupal_id
          name
        }
        field_experience_level {
          drupal_id
          name
        }
        field_position {
          drupal_id
          name
        }
        field_salary_type {
          drupal_id
          name
          description {
            processed
          }
        }
        field_expiration {
          drupal_id
          name
        }
        field_application_method {
          drupal_id
          name
        }
        field_education {
          drupal_id
          name
        }
        field_bilingual {
          drupal_id
          name
        }
        field_experience_years {
          drupal_id
          name
        }
      }
      field_required_documents
      field_application_url {
        uri
      }
    }
  }
  allTaxonomyTermDatesPosted {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermApplicationMethods {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermCategories {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermPositionTypes {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermSalaryTypes {
    nodes {
      drupal_id
      name
      description {
        processed
      }
    }
  }
  allTaxonomyTermExperienceLevels {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermDistances {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermExpirationLengths {
    nodes {
      drupal_id
      name
    }
  }
  allTaxonomyTermEducationLevels {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermBilingual {
    nodes {
      id
      drupal_id
      name
    }
  }
}

`;
