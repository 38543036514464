import React, {useContext, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {validateJob} from '../util'
import { SubmitButton } from '../components/carbon-entitysync';
import { handleLogout, UserContext, fetchAuthenticatedContent } from "@parallelpublicworks/entitysync"
import { ButtonSet} from 'carbon-components-react'
import {setAddressAndLocation} from "../util/setAddressAndLocation";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../util/recaptchaSubmit'

function SubmitListingButton({validationState, data, setHasErrors, setOpen, setUnsavedObject, geolocation, loading, setLoading}){
    
    const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
    const [goingAway, setGoingAway] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha();
    const userContext = useContext(UserContext)
    const [localID, setLocalID] = useState(null)
    
    function pingFn(){
      return fetchAuthenticatedContent(userContext[0].auth, userContext[1], 'node/article', 'GET')
      .then(resp => {
        return resp
      })
    }

    React.useEffect(() => {
      if (loading) {
        setUserDataChangesCount(userDataChangesCount + 1)
      }else{
        setUserDataChangesCount(0)
      }
    }, [userContext, loading])

    
  React.useEffect(() => {
    if (userDataChangesCount >= 3 && !goingAway) {
      setGoingAway(true)
      if (localID !== null) {
        navigate(`/preview?id=${localID}&new=1`);
      }
    }
  }, [userDataChangesCount])

    const onSubmit =  async (e, unsavedChanges, entityData) => {
      setLoading(true)
      e.persist()
      try {
        const ping = await pingFn()
        if(!ping){
          e.preventDefault();
          console.error('ping ERROR, logging out...');
          handleLogout(userContext);
          navigate('/login')
        }else{
          console.log('ping SUCCESS, continue saving...');
        }
        let isPreview = e.target.classList.contains('bx--btn--secondary');
        const valid = isPreview ? true : await recaptchaSubmit(executeRecaptcha);
        if (!valid) throw "Failed recaptcha"
        if(isPreview) e.preventDefault();
        let errorMsg = isPreview ? 'Please check all the required fields before previewing the job listing' : 'Please check all the required fields and submit the form again';
        if(!validateJob(entityData, validationState, data, geolocation)){
          e.preventDefault();
          setHasErrors(errorMsg);
          setLoading(false)
        }else{
          if(!unsavedChanges.attributes) unsavedChanges.attributes = {};
          unsavedChanges.attributes.field_publish_date = Math.round(new Date().getTime() / 1000);
          unsavedChanges.attributes.field_expired = false;
          setAddressAndLocation(unsavedChanges, geolocation);
          setHasErrors(null);
          if(!isPreview){
            setLocalID(unsavedChanges.local_id)
            //cleanup placeholder relationships
            if(unsavedChanges.relationships){
              for (let [rel_field_name, rel_data] of Object.entries(unsavedChanges.relationships)) {
                if(rel_data.data && !Array.isArray(rel_data.data)){
                  if(rel_data.data.id === 'placeholder-item'){
                    delete unsavedChanges.relationships[rel_field_name]
                  }
                }
              }
            }
          }else{
            localStorage.setItem('unsaved', JSON.stringify(unsavedChanges));
            setUnsavedObject(unsavedChanges);
            setOpen(true)
          }
        }
        
      } catch (err) {
        console.error(err)
        e.preventDefault();
      }
    };

    return (
      <ButtonSet className="submit-listing-btn-set">
        <SubmitButton onSubmit={onSubmit} kind="secondary" disabled={loading}>
          Preview
        </SubmitButton>
        <SubmitButton onSubmit={onSubmit} disabled={loading}>
          Submit listing
        </SubmitButton>
      </ButtonSet>
    );
}

export default SubmitListingButton;
